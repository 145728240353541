.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event_content_container {
  width: 30%;
  height: 60%;
  background-color: #fff;
  border-radius: 15px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.event_content_container>div:nth-child(1) {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
}

.header>button {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 15px;
  cursor: pointer;
}

.bonus_event_subscriptions_box {}

.bonus_event_subscriptions {
  list-style: circle;
  padding: 10px;
}

.bonus_event_subscriptions>li {
  font-size: 12px;
}

.bonus_event_subscriptions>li:not(.bonus_event_subscriptions>li:nth-child(1)) {
  line-height: 15px;
  margin-top: 10px;
}

.event_content_container>div:nth-child(2) {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.event_content_container>div:nth-child(2)>div:not(.header) {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event_content_container>div:nth-child(2)>div:not(.header)>div {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event_content_container>div:nth-child(2)>div:not(.header)>div>p {
  font-size: 35px;
  color: #000;
  text-align: center;
}

.event_content_container>div:nth-child(2)>div:not(.header)>div>h1 {
  font-size: 30px;
}

.event_content_container>div:nth-child(2)>div:not(.header)>button {
  width: 100%;
  height: 70px;
  border: none;
  outline: none;
  background-color: cornflowerblue;
  color: #fff;
}

.modal_textbox {
  /* display: flex; */
}

.modal_textbox button {
  display: flex;
  padding: 15px 40px;
}

.modal_textbox button p {
  margin: 0;
}

#modal_text {
  font-size: 14px;
  color: cornflowerblue;
  cursor: pointer;
  text-align: center;
  white-space: break-spaces;
  font-weight: 600;
}

@media (max-width: 1876px) {
  .event_content_container {
    width: 40%;
    height: 80%;
  }
}

@media (max-width: 1076px) {
  .event_content_container {
    width: 80%;
    height: 90%;
    overflow: scroll;
  }

  .header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
  }

  .event_content_container>div:nth-child(2)>div:not(.header)>div>p {
    font-size: 30px;
    color: #000;
  }

  .event_content_container>div:nth-child(2)>div:not(.header)>div {
    padding: 15px;
  }

  .event_content_container>div:nth-child(2)>div:not(.header)>div>h1 {
    font-size: 25px;
  }

  .event_content_container>div:nth-child(2)>div:not(.header)>button {
    height: 60px;
  }

  #modal_text {
    font-size: 12px;
  }

  .bonus_event_subscriptions>li {
    font-size: 12px;
  }
}