.container {
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .4);
    z-index: 9999999;
    top: 0;
    left: 0;
}

.wrap_container {
    width: 40%;
    height: 70%;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrap_container_signout {
    width: 20%;
    height: 50%;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    background-color: #000;
}

.header>button {
    border: none;
    outline: none;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
}

.content_container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content_container p {
    margin-bottom: 0;
}

.content_container a {
    color: blue;
    font-weight: bold;
}

.button_wrap {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_wrap>button {
    width: 250px;
    height: 55px;
    margin: 0 20px;
}

@media (max-width: 1076px) {
    .wrap_container {
        width: 80%;
        height: 70%;
    }

    .wrap_container_signout {
        width: 80%;
        padding: 15px 0;
    }

    .header {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px 20px;
    }

    .header>button {
        padding: 5px;
    }

    .header>button>img {
        width: 20px;
    }

    .content_container {
        padding: 0 15px;
    }

    .content_container h1 {
        font-size: 23px;
    }

    .content_container p {
        margin-bottom: 0;
        font-size: 13px;
        text-align: center;
    }

    .button_wrap>button {
        width: 250px;
        height: 35px;
        margin: 0 20px;
    }
}