.twitter_button {
    width: 100%;
    height: 55px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background-color: #000;
}

.twitter_button>img {
    width: 30px;
    height: 30px;
}

.twitter_button>#profile_image {
    border-radius: 50%;
}

.twitter_button>p {
    color: #fff;
    margin-bottom: 0;
    margin-left: 15px;
    font-weight: bold;
}