.cs_button {
    position: fixed;
    bottom: 50px;
    left: 50px;
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 50%;
    outline: none;
    z-index: 999;
    user-select: none;
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs_button>img {
    width: 31px;
}

.cs_button.firstBtn:focus {
    border: none;
    outline: none;
}

.cs_button:focus {
    outline: none;
    border: none;
}

@media (max-width: 1076px) {
    .cs_button {
        bottom: 30px;
        left: 30px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        outline: none;
        z-index: 999;
    }

    .cs_button>img {
        width: 20px;
    }
}