.container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 50px;
    margin-top: 15px;
}

.container p {
    margin-bottom: 0;
    color: #fff;
}

.container>input {
    margin-right: 8px;
}

.container p>span {
    color: #44DBCA;
    cursor: pointer;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.modal_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_container>div {
    width: 30%;
    height: 50%;
    border-radius: 30px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal_container>div>div:nth-child(2) {
    flex: 1;
    width: 100%;
    padding: 15px 25px;
    overflow-y: scroll;
}

.header h1 {
    margin-bottom: 0;
    font-size: 17px;
    font-weight: bold;
}

.header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}

.header button {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 15px;
    cursor: pointer;
}

.header>div:nth-child(1),
.header>div:nth-child(3) {
    width: 15%;
    display: flex;
    justify-content: flex-end;
}

.content_box {
    /* user-select: none; */
}

.content_box p {
    color: #000;
}

.content_box ul {
    margin-top: 25px;
}

.content_box ul:nth-child(1)>p:nth-child(2) {
    font-size: 13px;
}

.content_box ul>li {
    font-size: 13px;
}

@media (max-width: 1076px) {
    .container {
        padding: 0 10px;
    }

    .header {
        width: 100%;
        padding: 15px 0;
    }

    .modal_container>div {
        width: 90%;
        height: 70%;
    }

    .header h1 {
        font-size: 13px;
    }
}